<template>
  <div id="news-table" class="position-relative">
    <table class="table table-info table-bordered table-sm table-hover">
      <thead>
        <tr>
          <th width="200" class="text-center">วันที่</th>
          <th class="text-center">หัวข้อ / รายละเอียด</th>
          <th width="250" class="text-center">ผู้รับ</th>
          <th width="140" class="text-center">#</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item._id">
          <td class="text-center">{{ item.createdAt | dispDateTime('YYYY-MM-DD HH:mm') }}</td>
          <td>
            <div class="text-secondary">{{ item.subject }}</div>
            <!-- <div class="text-primary" v-html="item.htmlDetails"></div> -->
            <Content :data="item.htmlDetails" />
          </td>
          <td class="text-center">{{ item.receivers | receiverText }}</td>
          <td class="text-center">
            <button class="btn btn-sm btn-warning text-dark" @click="edit(item)">แก้ไข</button>
            <button class="btn btn-sm btn-danger m-2" @click="deleteNews(item)">ลบ</button>
          </td>
        </tr>
        <tr v-if="!items.length && !isLoading">
          <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <NewsForm :data="newsData" :isShow="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import NewsService from '@/services/newsService'
import Swal from 'sweetalert2'
import NewsForm from './NewsForm'
import Content from './Content'

export default {
  name: 'ListNews',
  props: ['type', 'reload'],
  components: {
    NewsForm,
    Content
  },
  data() {
    return {
      data: null,
      isLoading: false,
      isShowModal: false,
      newsData: null
    }
  },
  computed: {
    items() {
      if(!this.data)
        return []

      return this.data
    }
  },
  watch: {
    reload() {
      if(this.reload) {
        this.loadData()
      }
    }
  },
  methods: {
    loadData() {
      this.isLoading = true
      NewsService.getNews(this.type)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.$emit('loaded')
      })
    },
    edit(item) {
      this.newsData = item
      this.isShowModal = true
    },
    deleteNews(item) {
      Swal.fire({
        title: 'ยืนยัน!',
        text: "ต้องการลบข่าวประกาศ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return NewsService.deleteNews(item._id).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'ลบไม่สำเร็จ'),
              'error'
            )
          })
        }
      }).then((result) => {
        console.log(result)
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ลบเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.loadData()
          })
        }
      })
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadData()
      }
    }
  },
  mounted() {
    this.loadData()
  },
  filters: {
    receiverText(receivers) {
      const names = receivers.map((r)=>{
        return {
          'Company': 'บริษัท',
          'Agent': 'เอเย่นต์',
          'Member': 'เมมเบอร์'
        }[r]
      })
      return names.join(', ')
    }
  }
}
</script>
<style lang="scss">
#news-table {
  .table {
    tbody {
      background-color: #FFF;

      tr {
        td {
          vertical-align: top;
        }
      }
    }
  }
}
</style>
