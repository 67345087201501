<template>
  <b-modal
    ref="news-form-modal"
    hide-footer
    title="ข่าวประกาศ"
    @show="setData"
    @hidden="resetData"
    size="xl"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="หัวข้อประกาศ:"
      >
        <b-form-input
          placeholder="ใส่หัวข้อประกาศ"
          v-model="input.subject"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รายละเอียด:"
        description="สามารถใส่เป็น HTML ได้"
      >
        <b-form-textarea
          placeholder="รายละเอียดประกาศ"
          v-model="input.htmlDetails"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="ผู้รับ:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          :options="receivers"
          :aria-describedby="ariaDescribedby"
          v-model="input.receivers"
          name="receivers"
        ></b-form-checkbox-group>
      </b-form-group>

      <b-form-group label="การแสดง Popup:" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          :options="popupOptions"
          :aria-describedby="ariaDescribedby"
          v-model="input.popupDisplay"
          name="popupOptions"
        ></b-form-radio-group>
      </b-form-group>

      <hr>

      <div class="d-flex justify-content-end">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>

      <loading
        :active="isProcess"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </form>
  </b-modal>
</template>
<script>
import Swal from 'sweetalert2'
import NewsService from '@/services/newsService'

export default {
  name: 'NewsForm',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      receivers: [
        { text: 'บริษัท', value: 'Company' },
        { text: 'เอเย่นต์', value: 'Agent' },
        { text: 'เมมเบอร์', value: 'Member' }
      ],
      popupOptions: [
        { text: 'ไม่แสดง Popup', value: 'close' },
        { text: 'ครั้งเดียว', value: 'first' },
        { text: 'ทุกครั้ง', value: 'every' }
      ],
      input: {
        _id: null,
        type: '',
        subject: '',
        htmlDetails: '',
        receivers: [],
        popupDisplay: 'close'
      }
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    setData() {
      this.input = JSON.parse(JSON.stringify(this.data))
    },
    resetData() {
      this.isProcess = false
      this.input = {
        _id: null,
        type: '',
        subject: '',
        htmlDetails: '',
        receivers: [],
        popupDisplay: 'close'
      }
    },
    showModal() {
      this.$refs['news-form-modal'].show()
    },
    hideModal() {
      this.$refs['news-form-modal'].hide()
    },
    handleSubmit() {
      if(this.data._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      this.isProcess = true
      NewsService.createNews(this.input)
      .then((response)=>{
        if(response.success) {
          Swal.fire({
            text: 'บันทึกเสร็จเรียบร้อย',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          .then(()=>{
            this.needReload = true
            this.hideModal()
          })
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    update() {
      this.isProcess = true
      NewsService.updateNews(this.data._id, this.input)
      .then((response)=>{
        if(response.success) {
          Swal.fire({
            text: 'บันทึกเสร็จเรียบร้อย',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          .then(()=>{
            this.needReload = true
            this.hideModal()
          })
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
